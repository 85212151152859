module.exports = [{
      plugin: require('../plugins/gatsby-plugin-plausible-proxied/gatsby-browser.js'),
      options: {"plugins":[],"preconnectDomain":"https://stat.qed.ai","scriptURL":"https://stat.qed.ai/js/script.js","apiURL":"https://stat.qed.ai/api/event","domain":"$QED_PLAUSIBLE_SITE_DOMAIN"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#165FD9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
